import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    if (localStorage.getItem('__TOKEN__') == null) {
        return React.createElement(Navigate, { to: "../login", state: { from: location.pathname } });
    }
    return children;
};
export default ProtectedRoute;
